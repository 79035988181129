import React from 'react';


import TransCap from '../../TransCap/TransCap';

import classes from './Input.module.css';


const input = (props) => {

    const inputClasses = ['form-control'];

    let validationError = null;

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid);
        validationError = <p className={classes.ErrorMessage} >{props.valueType} <TransCap text='isNotValid' /> </p>
    }

    let inputElement = null;

    switch (props.elementType) {
        case ('input'):
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
            break;
        case ('textarea'):
            inputElement = <textarea
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
            break;
        case ('select'):
            inputElement = (
                <select
                    className={inputClasses.join(' ')}
                    value={props.value}
                    onChange={props.changed} >

                    {
                        props.elementConfig.options.map(option => (
                            <option
                                key={option.value}
                                value={option.value}>

                                {option.displayValue}
                            </option>
                        ))
                    }

                </select>
            );
            break;
        default:
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
    }

    return (
        <div className={classes.Input}>
            <label className={classes.Label} >{props.label}</label>
            {inputElement}
            {validationError}
        </div>
    );
}

export default input;