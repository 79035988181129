import React from 'react';

import classes from './SideHeader.module.css';

const sideHeader = props => {
    return ( 
        <div className={classes.SideHeader}>
            <h1> <strong> {props.text} </strong> </h1>
        </div>
     );
}
 
export default sideHeader;