import React from 'react';

import classes from './GalleryLoader.module.css';

const galleryLoader = () => {
    return ( 
        <div>
            <div className={classes.Image}></div>
            <div className={classes.Image}></div>
            <div className={classes.Image}></div>
            <div className={classes.Image}></div>
            <div className={classes.Image}></div>
            <div className={classes.Image}></div>
            <div className={classes.Image}></div>
            <div className={classes.Image}></div>
        </div>
     );
}
 
export default galleryLoader;