export default {
    mainNav: {
        home: 'home',
        menu: 'menu',
        location: 'location',
        gallery: 'gallery',
        contactUs: 'contact us'
    },
    main: {
        home: {
            ourChannel: 'our channel'
        },
        menu: {
            selectMenu: 'please select a menu'
        },
        location: {
            findUs: 'find us'
        },
        gallery: {
            ourGallery: 'our gallery'
        },
        contactUs: {
            sendUsAMsg: 'Send us a message',
            messageSent: 'Your message was sent successfully. Thank you.',
            name: 'name',
            namePh: 'Please enter your name',
            email: 'Email',
            emailPh: 'Please enter your email',
            subject: 'Subject',
            subjectPh: 'Please enter message subject',
            message: 'Message',
            messagePh: 'Please enter your message',
            send: 'Send'
        }
    },
    admin: {
        about: {
            whoAreWeFR: 'qui sommes nous',
            whoAreWeEN: 'who are we',
            about: 'About us',
        },
        menus: {
            menus: 'Menus',
            menuOrder: 'Menu Order'
        },
        items: {
            items: 'items',
            title: 'Titre',
            titleInFrench: 'Title in French',
            titleInEnglish: 'Title in English',
            description: 'La description',
            descriptionInFrench: 'description in French',
            descriptionInEnglish: 'description in English',
            price: 'Price'
        },
        contactInfo: {
            contactInfo: 'Contact Information'
        },
        social: {
            socialMedia: 'Social Media'
        },
        openingTimes: {
            dayEN: 'Day in English',
            dayFR: 'Day in French',
            openTime: 'Open Hour',
            closeTime: 'Close Hour',
            dayOrder: 'Day Order'
        },
        messages: {
            messages : 'Messages',
            noMessages : 'No messages'
        },
        visits: {
            websiteVisits: 'website visits',
            visitorsCount: 'Visitors count',
            totalVisitsCount: 'Total visits count',
            visitsCount: 'Visits count',
            totalVisits: 'Total visits'
        }
    },
    auth: {
        login: 'login',
        email: 'Email',
        password: 'Password',
        loginFailed: 'échec de la connexion'
    },
    address: {
        address: 'address',
        street: 'Street',
        city: 'City',
        country: 'Country',
        zip: 'Postal code',
    },
    openingTimes: {
        openingTimes: 'opening times'
    },
    save: 'save',
    saved: 'Saved',
    edit: 'edit',
    delete: 'delete',
    cancel: 'cancel',
    unavailable: 'unavailable',
    isNotValid: 'is not valid',
    aboutUs: 'about us',
    email: 'email',
    phone: 'phone',
    add: 'add',
    noData: 'no data yet',
    areYouSure: 'are you sure',
    youWantToDelete: 'you want to delete',
    yes: 'yes',
    no: 'no',
    deleting: 'deleting',
    errorOccured: 'An Error Occured',
    leavePhotoFieldEmpty: 'Leave this field empty if you wish to keep the current photo.',
    errorPhotoType: 'Please choose only one of these file types: .jpg, .jpeg, .png. gif',
    upload: 'Upload',
    file: 'File',
    uploadAFile: 'Upload a file',
    fileCaption: 'File caption',
    fileCaptionPh: 'Enter file caption',
    fileUploaded: 'File uploaded successfully',
    new: 'New',
    date: 'Date',
    close: 'Close',
    details: 'Details',
    dateAndTime: 'Date & Time',
    error: 'Error'
}