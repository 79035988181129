import firebase from 'firebase/app';
import 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCpcHVkZHjE2Z8l5Gm4ycXqrHfyWi_ROYw",
    authDomain: "resto-demo-1ba97.firebaseapp.com",
    databaseURL: "https://resto-demo-1ba97.firebaseio.com",
    projectId: "resto-demo-1ba97",
    storageBucket: "resto-demo-1ba97.appspot.com",
    messagingSenderId: "44103209086",
    appId: "1:44103209086:web:d7d6839703d0014f17c82f",
    measurementId: "G-EHCFQFQXEE"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

const storage = firebase.storage();

export {
    storage,
    firebase as default
}