import React, { Component } from 'react';
import { connect } from 'react-redux';
import counterpart from 'counterpart';

import axios from '../../../axios-firebase';

import TextHeader from '../../../components/UI/TextHeader/TextHeader';
import SideHeader from '../../../components/UI/SideHeader/SideHeader';
import TransCap from '../../../components/TransCap/TransCap';

import withErrorHandler from '../../../hoc/withErrorHandler';
import * as actions from '../../../store/actions/index';

import classes from './Location.module.css';

class Location extends Component {

    componentDidMount() {
        this.props.onFetchLocation();
        this.props.onFetchOTimes();
    }


    render() { 
        const lang = counterpart.getLocale();

        let map = <div className={classes.MapLoader}></div>;

        let address = <div className={classes.AddressBoxLoader}></div>;

        let oTimes = <div className={classes.oTimesBoxLoader}></div>;

        if(!this.props.loading){
            map = (
                  <iframe
                        title='googlemaps' 
                        src={this.props.location.google} 
                                height="450" 
                                className={classes.IFrame} >
                        </iframe>
            );

            address = (
                <div className={classes.InfoBox}>
                    <h4> {this.props.location.street} </h4>
                    <h4> {this.props.location.zip} {this.props.location.city}, {this.props.location.country} </h4>
                </div>
            );
        }


        if(!this.props.loadingOTimes && this.props.oTimes.length > 0) {
            oTimes = (
                <div className={classes.InfoBox}>
                    {
                        this.props.oTimes.map(t => (
                                <h4 key={t.id}> {lang === 'fr' ? t.day_FR : t.day_EN}: {t.openTime} - {t.closeTime} </h4>
                        ))
                    }
                </div>
            )
            
        }
      
        return ( 
            <div className={classes.Location}>
                <div className={classes.SideHeader}>
                    <SideHeader text=<TransCap text='mainNav.location' textStyle='uppercase' /> />
                </div>
                <div className={classes.SideContent}>
                     <div className={classes.AddressSide}>
                        <TextHeader text=<TransCap text='address.address' /> />
                        {address}
                        
                        <div className='mt-5'></div>
                        <TextHeader text=<TransCap text='openingTimes.openingTimes' /> />
                        {oTimes}
                    </div>

                    <div className={classes.MapSide}>
                        <TextHeader text=<TransCap text='main.location.findUs' /> />
                        {map}
                    </div>
                </div>
            </div> 
         );
    }
}
 
const mapStateToProps = state => {
    return {
        location: state.address.data,
        loading: state.address.loading,
        oTimes: state.oTimes.data,
        loadingOTimes: state.oTimes.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchLocation: () => dispatch(actions.fetchAddress()),
        onFetchOTimes: () => dispatch(actions.fetchOTimes())
    }; 
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Location, axios));