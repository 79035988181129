import React from 'react';

import classes from './Spinner.module.css';

const spinner = props => {
    return (
        <div className={classes['sk-chase']} style={{ width: props.size, height: props.size }}>
            <div className={classes['sk-chase-dot']}></div>
            <div className={classes['sk-chase-dot']}></div>
            <div className={classes['sk-chase-dot']}></div>
            <div className={classes['sk-chase-dot']}></div>
            <div className={classes['sk-chase-dot']}></div>
            <div className={classes['sk-chase-dot']}></div>
        </div>


        // <div className={classes.spinner} style={{ width: props.size, height: props.size }}>
        //     <div className={[classes['spinner-circle'],  classes['spinner-circle-outer']].join(' ')}></div>
        //     <div className={[classes['spinner-circle-off'],  classes['spinner-circle-inner']].join(' ')}></div>
        //     <div className={[classes['spinner-circle'],  classes['spinner-circle-single-1']].join(' ')}></div>
        //     <div className={[classes['spinner-circle'],  classes['spinner-circle-single-2']].join(' ')}></div>
        // </div>
    );
}

export default spinner;