import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../../shared/utilities';

const initalState = {
    data: [],
    loading: false,
    deleting: false,
    error: null,
    sent: false
};

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.MESSAGE_SEND_INIT:
            return updateObject(state, { loading: false, sent: false, error: null });

        case actionTypes.MESSAGE_SEND_START:
            return updateObject(state, { loading: true, sent: false });

        case actionTypes.MESSAGE_SEND_SUCCESS:
            const newMessage = updateObject(action.data, { id: action.id });

            return updateObject(state, {
                loading: false,
                error: null,
                sent: true,
                data: state.data.concat(newMessage)
            });

        case actionTypes.MESSAGE_SEND_FAIL:
            return updateObject(state, { loading: false, error: action.error, sent: false });

        // Fetch
        case actionTypes.MESSAGES_FETCH_START:
            return updateObject(state, { loading: true });

        case actionTypes.MESSAGES_FETCH_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: action.data
            });

        case actionTypes.MESSAGES_FETCH_FAIL:
            return updateObject(state, { loading: false, error: action.error });


        // Find by ID
        case actionTypes.MESSAGE_FINDBYID_START:
            return updateObject(state, { loading: true });

        case actionTypes.MESSAGE_FINDBYID_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: action.data
            });
        case actionTypes.MESSAGE_FINDBYID_FAIL:
            return updateObject(state, { loading: false, error: action.error });


        // Delete
        case actionTypes.MESSAGE_DELETE_START:
            return updateObject(state, { deleting: true, });

        case actionTypes.MESSAGE_DELETE_SUCCESS:
            return updateObject(state, { deleting: false });

        case actionTypes.MESSAGE_DELETE_FAIL:
            return updateObject(state, { deleting: false, error: action.error });


        case actionTypes.MESSAGE_MARK_SUCCESS:
            return updateObject(state, {  });


        default: return state;
    }
}

export default reducer;