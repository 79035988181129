import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';

// Fetch

export const visitorsFetchStart = _ => {
    return {
        type: actionTypes.VISITORS_FETCH_START
    };
};

export const visitorsFetchSuccess = (data) => {
    return {
        type: actionTypes.VISITORS_FETCH_SUCCESS,
        data: data
    };
};

export const visitorsFetchFail = error => {
    return {
        type: actionTypes.VISITORS_FETCH_FAIL,
        error: error
    };
};

const fetchVisitorsHelper = (dispatch, token) => {
     axios.get('/visits.json?auth=' + token)
            .then(response => {
                const fetchedData = [];
                for (let key in response.data) {
                    const visits = [];
                    for(let innerKey in response.data[key]){
                        visits.push({
                            ...response.data[key][innerKey],
                            id: innerKey
                        })
                    }
                    fetchedData.push({
                        visits: visits,
                        id: key,
                    });
                }
                // console.log(fetchedData);
                dispatch(visitorsFetchSuccess(fetchedData));
            })
            .catch(error => {
                dispatch(visitorsFetchFail(error));
            });
};


export const fetchVisitors = (token) => {
    return dispatch => {
        dispatch(visitorsFetchStart());

        fetchVisitorsHelper(dispatch, token);
       
    }
};