import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../../shared/utilities';

const initialState = {
    data: {},
    loading: false,
    error: null,
    saved: false,
    saving: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADDRESS_UPDATE_START:
            return updateObject(state, { saving: true, saved: false });

        case actionTypes.ADDRESS_UPDATE_SUCCESS:
            const newData = updateObject(state.data, action.data);

            return updateObject(state, { saving: false, saved: true, data: newData });

        case actionTypes.ADDRESS_UPDATE_SUCCESS_END:
            return updateObject(state, { saved: false });

        case actionTypes.ADDRESS_UPDATE_FAIL:
            return updateObject(state, { saving: false, error: action.error, saved: false  });

        
        case actionTypes.ADDRESS_FETCH_START:
            return updateObject(state, { loading: true });

        case actionTypes.ADDRESS_FETCH_SUCCESS:
            return updateObject(state, { loading: false, data: action.data });

        case actionTypes.ADDRESS_FETCH_FAIL:
            return updateObject(state, { loading: false, error: action.error });


        default:
            return state;
    }
};


export default reducer;
