import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';


export const oTimeAddInit = () => {
    return {
        type: actionTypes.OTIME_ADD_INIT
    };
}

export const oTimeAddStart = _ => {
    return {
        type: actionTypes.OTIME_ADD_START
    };
};

export const oTimeAddSuccess = (id, data) => {
    return {
        type: actionTypes.OTIME_ADD_SUCCESS,
        id: id,
        data: data
    };
};

export const oTimeUpdateSuccess = ( data) => {
    return {
        type: actionTypes.OTIME_ADD_SUCCESS,
        data: data
    };
};

export const oTimeAddFail = error => {
    return {
        type: actionTypes.OTIME_ADD_FAIL,
        error: error
    };
};

export const addOTime = (data, token) => {
    return dispatch => {
        dispatch(oTimeAddStart());

        axios.post('/opening-times.json?auth=' + token, data)
            .then(response => {
                dispatch(oTimeAddSuccess(response.data.name ,data));
            })
            .catch(error => {
                dispatch(oTimeAddFail(error));
            });
    }
};


export const updateOTime = (data, token, oTimeId) => {
    return dispatch => {
        dispatch(oTimeAddStart());

        axios.patch('/opening-times/' + oTimeId + '.json?auth=' + token, data)
            .then(response => {
                dispatch(oTimeUpdateSuccess(data));
            })
            .catch(error => {
                dispatch(oTimeAddFail(error));
            });
    };
};


// Fetch

export const oTimesFetchStart = () => {
    return {
        type: actionTypes.OTIMES_FETCH_START
    };
};

export const oTimesFetchSuccess = (data) => {
    return {
        type: actionTypes.OTIMES_FETCH_SUCCESS,
        data: data
    };
};

export const oTimesFetchFail = error => {
    return {
        type: actionTypes.OTIMES_FETCH_FAIL,
        error: error
    };
};

const fetchOTimesHelper = dispatch => {
     axios.get('/opening-times.json')
            .then(response => {
                const fetchedData = [];
                for (let key in response.data) {
                    fetchedData.push({
                        ...response.data[key],
                        id: key,
                    });
                }
                fetchedData.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
                dispatch(oTimesFetchSuccess(fetchedData));
            })
            .catch(error => {
                dispatch(oTimesFetchFail(error));
            });
};


export const fetchOTimes = () => {
    return dispatch => {
        dispatch(oTimesFetchStart());

        fetchOTimesHelper(dispatch);
       
    }
};

// Delete

export const oTimeDeleteStart = _ => {
    return {
        type: actionTypes.OTIME_DELETE_START
    };
};

export const oTimeDeleteSuccess = () => {
    return {
        type: actionTypes.OTIME_DELETE_SUCCESS,
    };
};

export const oTimeDeleteFail = error => {
    return {
        type: actionTypes.OTIME_DELETE_FAIL,
        error: error
    };
};

export const deleteOTime = (id, token) => {
    return dispatch => {
        dispatch(oTimeDeleteStart());

        axios.delete('/opening-times/' + id + '.json?auth=' + token)
            .then(response => {
                dispatch(oTimeDeleteSuccess());
                fetchOTimesHelper(dispatch);
            })
            .catch(error => {
                dispatch(oTimeDeleteFail(error));
            });
    }
};
