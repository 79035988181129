import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';

export const messageSendInit = () => {
    return {
        type: actionTypes.MESSAGE_SEND_INIT
    };
}

export const messageSendStart = _ => {
    return {
        type: actionTypes.MESSAGE_SEND_START
    };
};

export const messageSendSuccess = (id, data) => {
    return {
        type: actionTypes.MESSAGE_SEND_SUCCESS,
        id: id,
        data: data
    };
};


export const messageSendFail = error => {
    return {
        type: actionTypes.MESSAGE_SEND_FAIL,
        error: error
    };
};


export const sendmessage = data => {
    return dispatch => {
        dispatch(messageSendStart());
        
        axios.post('/messages.json', data)
            .then(response => {
                dispatch(messageSendSuccess(response.data.name, data));
            })
            .catch(error => {
                dispatch(messageSendFail(error));
            });
    };
};


// Fetch

export const messagesFetchStart = _ => {
    return {
        type: actionTypes.MESSAGES_FETCH_START
    };
};

export const messagesFetchSuccess = (data) => {
    return {
        type: actionTypes.MESSAGES_FETCH_SUCCESS,
        data: data
    };
};

export const messagesFetchFail = error => {
    return {
        type: actionTypes.MESSAGES_FETCH_FAIL,
        error: error
    };
};

const fetchMessagesHelper = (dispatch, token) => {
    axios.get('/messages.json?auth=' + token)
        .then(response => {

            const fetchedData = [];
            for (let key in response.data) {
                fetchedData.push({
                    ...response.data[key],
                    id: key,
                });
            }
            fetchedData.sort((a,b) => (a.datetime < b.datetime) ? 1 : ((b.datetime < a.datetime) ? -1 : 0));
            dispatch(messagesFetchSuccess(fetchedData));
        })
        .catch(error => {
            dispatch(messagesFetchFail(error));
        });
};


export const fetchMessages = (token) => {
    return dispatch => {
        dispatch(messagesFetchStart());

        fetchMessagesHelper(dispatch, token);
    };
};

/// Find By Id

export const messageFindByIdStart = () => {
    return {
        type: actionTypes.MESSAGE_FINDBYID_START
    };
};

export const messageFindByIdSuccess = (data) => {
    return {
        type: actionTypes.MESSAGE_FINDBYID_SUCCESS,
        data: data
    };
};

export const messageFindByIdFail = error => {
    return {
        type: actionTypes.MESSAGE_FINDBYID_FAIL,
        error: error
    };
};

const messageFindByIdHelper = (dispatch, id, token) => {
    dispatch(messageFindByIdStart());

    axios.get('/messages/' + id + '.json?auth=' + token)
        .then(response => {

            const fetchedData = [];

            fetchedData.push({
                ...response.data,
                id: id,
            });

            dispatch(messageFindByIdSuccess(fetchedData));
        })
        .catch(error => {
            dispatch(messageFindByIdFail(error));
        });
};

export const messageFindById = (id, token) => {
    return dispatch => {
        messageFindByIdHelper(dispatch, id, token);
    };
};



// Delete


export const messageDeleteStart = () => {
    return {
        type: actionTypes.MESSAGE_DELETE_START
    };
};

export const messageDeleteSuccess = () => {
    return {
        type: actionTypes.MESSAGE_DELETE_SUCCESS,
    };
};

export const messageDeleteFail = error => {
    return {
        type: actionTypes.MESSAGE_DELETE_FAIL,
        error: error
    };
};


export const deleteMessage = (id, token) => {
    return dispatch => {
        dispatch(messageDeleteStart());

        axios.delete('/messages/' + id + '.json?auth=' + token)
            .then(response => {
                dispatch(messageDeleteSuccess());
                fetchMessagesHelper(dispatch,token);
            })
            .catch(error => {
                dispatch(messageDeleteFail(error));
            });
    };
};


// Mark as read

export const messageMarkSuccess = () => {
    return {
        type: actionTypes.MESSAGE_MARK_SUCCESS,
    };
};

export const markMessageAsRead = (id, data) => {
    return dispatch => {        
        axios.patch('/messages/' + id + '.json', data)
            .then(response => {
                dispatch(messageMarkSuccess());
            });
    };
};