import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import counterpart from 'counterpart';

import axios from '../../../axios-firebase';

import HomeLoader from '../../../components/UI/HomeLoader/HomeLoader';
import FacebookButton from '../../../components/FacebookButton/FacebookButton';
import InstagramButton from '../../../components/InstagramButton/InstagramButton';
import YoutubeButton from '../../../components/YoutubeButton/YoutubeButton';

import withErrorHandler from '../../../hoc/withErrorHandler';
import * as actions from '../../../store/actions/index';

import classes from './Home.module.css';

class Home extends Component {

    componentDidMount() {
        this.props.onFetchAbout();   
        this.props.onFetchSocial(); 

    }

    render() { 
        const lang = counterpart.getLocale();

        let about = null;
        let info = null;
        let aboutImg = null;
        let homeRender = <HomeLoader />;

        let fbInst = null;
        let yt = null;

        if(!this.props.loadingSocial && this.props.social.facebook && this.props.social.Instagram){
            fbInst = (
                <div className={classes.SocialLinks}>
                    <FacebookButton link={this.props.social.facebook} />
                    <InstagramButton link={this.props.social.Instagram} />
                </div>
            );
        }

        if(!this.props.loadingSocial && this.props.social.Youtube){
            yt = <YoutubeButton link={this.props.social.Youtube} />;
        }

        if(!this.props.loadingAbout){

            switch (lang) {
                case 'fr':
                    about = ReactHtmlParser(this.props.about.about_FR);
                    info = this.props.about.Info_FR;
                    break;
                case 'en':
                    about = ReactHtmlParser(this.props.about.about_EN);
                    info = this.props.about.Info_EN;
                    break;
                default:
                    about = ReactHtmlParser(this.props.about.about_FR);
                    info = this.props.about.Info_FR;
                    break;
            }
  
            aboutImg = <img src={this.props.about.imageUrl} alt='' style={{width: '100%', boxShadow: '3px 3px 3px rgb(48, 48, 48)'}} />;

            homeRender = (
                 <div className='row'>
                    <div className='col-lg-7' style={{textAlign: 'justify'}}>
                        {about}
                        {yt}
                    </div>
                     <div className={['col-lg-5', classes.Right].join(' ')}>
                        {aboutImg}
                        
                        {fbInst}
                    </div>
                </div>
            );
        }

        return ( 
            <div className={classes.Home}>
                <h1> My Resto </h1>
                <p style={{fontStyle: 'italic'}}> {info} </p>
                {homeRender}
            </div>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        about: state.info.data,
        loadingAbout: state.info.loading,
        social: state.social.data,
        loadingSocial: state.social.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchAbout: () => dispatch(actions.fetchInfo()),
        onFetchSocial: () => dispatch(actions.fetchSocial())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Home, axios));