import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import counterpart from 'counterpart';
import { store } from 'react-notifications-component';
// import Moment from 'react-moment';
// import 'moment-timezone';
// import { connect } from 'react-redux';

import withErrorHandler from '../../hoc/withErrorHandler';
import axios from '../../axios-firebase';
// import * as actions from '../../store/actions/index';

// import Modal from '../../components/UI/Modal/Modal';

import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import Logo from '../../components/Logo/Logo';

import Home from './Home/Home';
import Menu from './Menu/Menu';
import Location from './Location/Location';
import ContactUs from './ContactUs/ContactUs';
import Gallery from './Gallery/Gallery';

// import chain from '../../assets/images/c.png';

import classes from './Layout.module.scss';


class Layout extends Component {

    state = {
        showSideDrawer: false,
        // showContent: false,
        // modal: false,
        lang: 'fr'
    };

    componentDidMount() {
        const publicIp = require('public-ip');
        let IPV4 = null;

        (async () => {
                IPV4 = await publicIp.v4();
            })().then(() => {
                // console.log(IPV4);
                const IPTEXT = IPV4.toString().split('.').join('-');
                const dt = new Date();
                axios.post('/visits/'+ IPTEXT  + '.json', {
                    IPV4: IPV4,
                    datetime: dt.getDate() + '/' + (+dt.getMonth() + 1) + '/' + dt.getFullYear() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
                })
            });
    }   

    sideDrawerClosedHandler = () => {
        this.setState({ showSideDrawer: false });
    };


    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        });
    };


    // openModalHandler = () => {
    //     this.setState({ ...this.state, modal: true });
    // };

    // closeModalHandler = () => {
    //     this.setState({ ...this.state, modal: false });
    // };

    // changeLanguageHandler = event => {
    //     this.setState({ ...this.state, lang: event.target.value });
    //     counterpart.setLocale(event.target.value);
    // }

    setLang = lang => {
        this.setState({ ...this.state, lang: lang});
        counterpart.setLocale(lang);

        
        store.addNotification({
            title: lang === 'fr'? 'Langue Changée' :  'Language Changed !',
            message: lang === 'fr' ? 'Français' : 'English',
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
                duration: 3000,
                onScreen: true,
                pauseOnHover: true
            },
            touchSlidingExit: {
                swipe: {
                duration: 400,
                timingFunction: 'ease-out',
                delay: 0,
                },
                fade: {
                duration: 400,
                timingFunction: 'ease-out',
                delay: 0
                }
            }
        });
    }


    render() {

        const clsEN = this.state.lang === 'en' ? classes.EN : '';
        const clsFR = this.state.lang === 'fr' ? classes.FR : '';
        

        return (
            <div className={classes.Layout}>
                {/* <img src={chain} className={[classes.Chain, classes.ChainL].join(' ')} alt='' /> */}
                {/* <img src={chain} className={[classes.Chain, classes.ChainR].join(' ')} alt='' /> */}
                {/* <div className={[classes.Hanger, classes.HangerL].join(' ')}> </div>
                <div className={[classes.Hanger, classes.HangerR].join(' ')}> </div> */}
                <div className={classes.Content}>
                    <div className='row'>
                        <div className={['col-lg-3', classes.Logo].join(' ')}>
                            <div >
                                <div className='row'>
                                    <div className='col-lg-8'>
                                        <Logo />
                                    </div>
                                    <div className='col-lg-4' > 
                                        <div className={classes.Languages}>
                                            <button 
                                                className={['btn-link', clsFR].join(' ')}
                                                onClick={this.state.lang !== 'fr' ? () => this.setLang('fr') : null}>
                                             FR 
                                            </button>
                                            |
                                            <button
                                                className={['btn-link', clsEN].join(' ')}
                                                onClick={this.state.lang !== 'en' ? () => this.setLang('en') : null}>
                                            EN 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-9'>
                            <div className={classes.Toolbar}>
                                <Toolbar drawerToggleClicked={this.sideDrawerToggleHandler} />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='container' style={{marginTop: '10px'}}>
                                <Switch>
                                    <Route path="/" exact component={Home} />
                                    <Route path="/menu"  component={Menu} />
                                    <Route path="/location"  component={Location} />
                                    <Route path="/contact-us"  component={ContactUs} />
                                    <Route path="/gallery"  component={Gallery} />
                                </Switch>

                                <SideDrawer
                                    closed={this.sideDrawerClosedHandler}
                                    open={this.state.showSideDrawer} />
                            </div>
                        </div>
                    </div>
                </div>

                <footer className={classes.Footer}>
                    <p> &copy; 2020 By Sharif Qasrawi </p>
                </footer>
            </div>
        );
    }
}


export default withErrorHandler(Layout, axios);
