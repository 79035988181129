import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';
import { storage } from '../../firebase';

export const galleryAddInit = () => {
    return {
        type: actionTypes.GALLERY_ADD_INIT
    };
}

export const galleryAddStart = _ => {
    return {
        type: actionTypes.GALLERY_ADD_START
    };
};

export const galleryAddSuccess = (id, data) => {
    return {
        type: actionTypes.GALLERY_ADD_SUCCESS,
        id: id,
        data: data
    };
};

export const galleryUpdateSuccess = ( data) => {
    return {
        type: actionTypes.GALLERY_ADD_SUCCESS,
        data: data
    };
};

export const galleryAddFail = error => {
    return {
        type: actionTypes.GALLERY_ADD_FAIL,
        error: error
    };
};

export const addGalleryImage = (data, token) => {
    return dispatch => {
        dispatch(galleryAddStart());

        axios.post('/gallery.json?auth=' + token, data)
            .then(response => {
                dispatch(galleryAddSuccess(response.data.name ,data));
            })
            .catch(error => {
                dispatch(galleryAddFail(error));
            });
    }
};

// Fetch

export const galleryFetchStart = _ => {
    return {
        type: actionTypes.GALLERY_FETCH_START
    };
};

export const galleryFetchSuccess = (data) => {
    return {
        type: actionTypes.GALLERY_FETCH_SUCCESS,
        data: data
    };
};

export const galleryFetchFail = error => {
    return {
        type: actionTypes.GALLERY_FETCH_FAIL,
        error: error
    };
};

const fetchGalleryHelper = dispatch => {
     axios.get('/gallery.json')
            .then(response => {
                const fetchedData = [];
                for (let key in response.data) {
                    fetchedData.push({
                        ...response.data[key],
                        id: key,
                    });
                }
                dispatch(galleryFetchSuccess(fetchedData));
            })
            .catch(error => {
                dispatch(galleryFetchFail(error));
            });
};


export const fetchGallery = () => {
    return dispatch => {
        dispatch(galleryFetchStart());

        fetchGalleryHelper(dispatch);
       
    }
};

// Delete

export const galleryDeleteStart = _ => {
    return {
        type: actionTypes.GALLERY_DELETE_START
    };
};

export const galleryDeleteSuccess = () => {
    return {
        type: actionTypes.GALLERY_DELETE_SUCCESS,
    };
};

export const galleryDeleteFail = error => {
    return {
        type: actionTypes.GALLERY_DELETE_FAIL,
        error: error
    };
};

export const deleteGalleryImage = (id, token) => {
    return dispatch => {
        dispatch(galleryDeleteStart());

        axios.delete('/gallery/' + id + '.json?auth=' + token)
            .then(response => {
                dispatch(galleryDeleteSuccess());
                fetchGalleryHelper(dispatch);
            })
            .catch(error => {
                dispatch(galleryDeleteFail(error));
            });
    }
};

// Upload

export const uploadFileStart = () => {
    return {
        type: actionTypes.GALLERY_UPLOADFILE_START
    };
};

export const uploadFileProgress = progress => {
    return {
        type: actionTypes.GALLERY_UPLOADFILE_PROGRESS,
        progress: progress
    };
};

export const uploadFileSuccess = (id) => {
    return {
        type: actionTypes.GALLERY_UPLOADFILE_SUCCESS,
        id: id
    };
};

export const uploadFileFail = error => {
    return {
        type: actionTypes.GALLERY_UPLOADFILE_FAIL,
        error: error
    };
};

export const uploadFile = (data, token, cfile) => {
    return dispatch => {
        dispatch(uploadFileStart());

        const file = cfile;
        const fname = new Date().getTime() + '_' + file.name;

        const uploadTask = storage.ref(`images/${fname}`).put(file);

        uploadTask.on('state_changed',
            (snapshot) => {
                // progrss function ....
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                dispatch(uploadFileProgress(progress));
            },
            (error) => {
                // error function ....
                //console.log(error);
                dispatch(uploadFileFail(error));
            },
            () => {
                // complete function ....
                storage.ref('images').child(fname).getDownloadURL().then(url => {

                    const dataWithFile = {
                        ...data,
                        imageUrl: url
                    }

                    axios.post('/gallery.json?auth=' + token, dataWithFile)
                        .then(response => {
                            dispatch(uploadFileSuccess(response.data.name));
                            fetchGalleryHelper(dispatch);
                        })
                        .catch(error => {
                            dispatch(uploadFileFail(error));
                        });
                })
            });
    };
};