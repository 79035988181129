export default {
    mainNav: {
        home: 'accueil',
        menu: 'menu',
        location: 'localisation',
        gallery: 'galerie',
        contactUs: 'nous contacter'
    },
    main: {
        home: {
            ourChannel: 'notre chaîne'
        },
        menu: {
            selectMenu: 'veuillez sélectionner un menu'
        },
        location: {
            findUs: 'nous localiser'
        },
        gallery: {
            ourGallery: 'notre galerie'
        },
        contactUs: {
            sendUsAMsg: 'Envoyez-nous un message',
            messageSent: 'votre message a été envoyé avec succès. Merci',
            name: 'Nom',
            namePh: 'Veuillez saisir votre nom',
            email: 'Email',
            emailPh: 'Veuillez saisir votre e-mail',
            subject: 'Objet du message',
            subjectPh: 'Veuillez saisir l\'objet du message',
            message: 'Message',
            messagePh: 'Veuillez saisir votre message',
            send: 'Envoyer'
        }
    },
    auth: {
        login: 's\'identifier',
        email: 'Email',
        password: 'Mot de passe',
        loginFailed: 'échec de la connexion'
    },
    admin: {
        about: {
            whoAreWeFR: 'qui sommes nous',
            whoAreWeEN: 'who are we',
            about: 'à propos',
        },
        menus: {
            menus: 'Menus',
            menuOrder: 'Ordre de menu'
        },
        items: {
            items: 'articles',
            title: 'Titre',
            titleInFrench: 'Titre en français',
            titleInEnglish: 'Titre en anglais',
            description: 'La description',
            descriptionInFrench: 'La description en français',
            descriptionInEnglish: 'La description en anglais',
            price: 'Prix'
        },
        contactInfo: {
            contactInfo: 'Informations de contact'
        },
        social: {
            socialMedia: 'Des réseaux sociaux'
        },
        openingTimes: {
            dayFR: 'Jour en français',
            dayEN: 'Jour en anglais',
            openTime: 'Heure d\'ouverture',
            closeTime: 'Heure de fermeture',
            dayOrder: 'Ordre du jour'
        },
        messages: {
            messages : 'Messages',
            noMessages : 'Pas de messages'
        },
        visits: {
            websiteVisits: 'visites du site',
            visitorsCount: 'nombre de visiteurs',
            totalVisitsCount: 'nombre total de visites',
            visitsCount: 'nombre de visites',
            totalVisits: 'total des visites'
        }
    },
    address: {
        address: 'adresse',
        street: 'Rue',
        city: 'Ville',
        country: 'Pays',
        zip: 'Code postale',
    },
    openingTimes: {
        openingTimes: 'horaires d\'ouverture'
    },
    save: 'enregistrer',
    saved: 'Enregistré',
    edit: 'modifier',
    delete: 'supprimer',
    cancel: 'annuler',
    unavailable: 'indisponible',
    isNotValid: 'n\'est pas valide',
    aboutUs: 'qui sommes nous',
    email: 'email',
    phone: 'téléphone',
    add: 'ajouter',
    noData: 'aucune donnée',
    areYouSure: 'êtes-vous sûr',
    youWantToDelete: 'vous souhaitez supprimer',
    yes: 'oui',
    no: 'non',
    deleting: 'suppression',
    errorOccured: 'Une erreur s\'est produite',
    leavePhotoFieldEmpty: 'Laissez ce champ vide si vous souhaitez conserver la photo actuelle.',
    errorPhotoType: 'Veuillez choisir un seul de ces types de fichiers: .jpg, .jpeg, .png. gif',
    upload: 'Télécharger',
    file: 'Fichier',
    uploadAFile: 'Télécharger un fichier',
    fileCaption: 'Légende de fichier',
    fileCaptionPh: 'Saisir la légende du fichier',
    fileUploaded: 'Fichier téléchargé avec succès',
    new: 'Nouveau',
    date: 'La date',
    close: 'Fermer',
    details: 'Détails',
    dateAndTime: 'Date & l\'heure',
    error: 'Erreur',
}