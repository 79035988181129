import React, { Component } from 'react';
import { connect } from 'react-redux';
import counterpart from 'counterpart';

import axios from '../../../axios-firebase';

import MenuLoader from '../../../components/UI/MenuLoader/MenuLoader';
import MenuButtonsLoader from '../../../components/UI/MenuButtonsLoader/MenuButtonsLoader';
import TextHeader from '../../../components/UI/TextHeader/TextHeader';
import SideHeader from '../../../components/UI/SideHeader/SideHeader';
import Item from '../../../components/Main/Item/Item';
import TransCap from '../../../components/TransCap/TransCap';

import withErrorHandler from '../../../hoc/withErrorHandler';
import * as actions from '../../../store/actions/index';

import menuImg from '../../../assets/images/menu.png';

import classes from './Menu.module.css';

class Menu extends Component {

    state = {
        isMenuSelected: false,
        selectedMenuId: ''
    };

    componentDidMount() {
        this.props.onFetchMenus();
        // this.props.onFetchItems();
    }

    selectMenuHandler = id => {
        this.props.onFetchItems(id);
        this.setState({
            ...this.state,
            isMenuSelected: true,
            selectedMenuId: id
        });
    };

    render() { 
        const lang = counterpart.getLocale();

        let menu = <MenuLoader />;
        let menuButtons = <MenuButtonsLoader />;

        if(!this.props.loading  && this.props.items.length > 0) {
            menu = this.props.items.map(m => (
                <div key={m.id} className='mb-4'>
                    {   
                        m.items.length === 0 ? null
                        : <div>
                             <TextHeader text={<span style={{textTransform: 'uppercase'}}> 
                             {lang === 'fr' ? m.menu_FR : m.menu_EN} 
                             </span>}   
                             />
                                {
                                    m.items.map(i => <Item 
                                        key={i.id}
                                        img={i.imageUrl}
                                        title={lang === 'fr' ? i.title_FR : i.title_EN}
                                        description={lang === 'fr' ? i.description_FR : i.description_EN}
                                        price={i.price}
                                    />)
                                }
                            <div className='clearfix'> </div>
                        </div>
                    }
                   
                </div>
            ))
        }

        if(!this.state.isMenuSelected){
            menu = (
                <div className={classes.MenuPlaceHolder}>
                    <img src={menuImg} alt='' />
                    <h1> <TransCap text='main.menu.selectMenu' /> </h1>
                </div>
            );
        }

        const btnMenuClass = [classes.BtnMenu];

        if(!this.state.isMenuSelected){
            btnMenuClass.push(classes.BtnMenuAnimated);
        }

        if(!this.props.loading  && this.props.menus.length > 0) {
            menuButtons = (
                <div className={classes.MenuBtns}>
                    {
                        this.props.menus.map(m => (
                            <div 
                                key={m.id} 
                                className={btnMenuClass.join(' ')}
                                onClick={() => this.selectMenuHandler(m.id)}>
                                <strong style={{textTransform: 'uppercase'}}> {lang === 'fr' ? m.menu_FR : m.menu_EN}  </strong>
                            </div>  
                        ))
                    }
                </div>
            )
        }

        return ( 
            <div className={classes.Menu}>
                <SideHeader text=<TransCap text='mainNav.menu' textStyle='uppercase' /> />
                <div className={classes.MenuContent}>
                    {menuButtons}

                    {menu}
                </div>
            </div>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        items: state.items.data,
        menus: state.menus.data,
        loading: state.items.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchItems: (id) => dispatch(actions.fetchItems(id)),
        onFetchMenus: () => dispatch(actions.fetchMenus())
    }; 
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Menu, axios));