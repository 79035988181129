import React from 'react';

import TransCap from '../TransCap/TransCap';

import classes from './YoutubeButton.module.css';

const youtubeButton = props => {
    return ( 
        <a href={props.link} target='_blank' rel="noopener noreferrer"  className={classes.Link}>
            <div className={classes.YoutubeButton}>
                <i className='fa fa-youtube-play'> </i> <TransCap text='main.home.ourChannel' />
            </div> 
        </a> 
     );
}
 
export default youtubeButton;