import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';
import { storage } from '../../firebase';

//// Update data

export const InfoUpdateStart = _ => {
    return {
        type: actionTypes.INFO_UPDATE_START
    };
};

export const InfoUpdateSuccess = (data) => {
    return {
        type: actionTypes.INFO_UPDATE_SUCCESS,
        data: data
    };
};

export const InfoUpdateSuccessEnd = () => {
    return {
        type: actionTypes.INFO_UPDATE_SUCCESS_END,
    };
};


export const InfoUpdateFail = error => {
    return {
        type: actionTypes.INFO_UPDATE_FAIL,
        error: error
    };
};

export const updateInfo = (data, token, imageData) => {
    return dispatch => {
        dispatch(InfoUpdateStart());

        if (imageData) {

        const image = imageData;

        const uploadTask = storage.ref(`images/${image.name}`).put(image);

        uploadTask.on('state_changed',
            (snapshot) => {
                // progrss function ....
            },
            (error) => {
                // error function ....
                //console.log(error);
                dispatch(InfoUpdateFail(error));
            },
            () => {
                // complete function ....
                storage.ref('images').child(image.name).getDownloadURL().then(url => {

                    const dataWithImage = {
                        ...data,
                        imageUrl: url,
                        image: {
                            imageUrl: url,
                            imageSize: image.size,
                            imageName: image.name
                        }
                    }

                    axios.patch('/info.json?auth=' + token, dataWithImage)
                        .then(response => {
                            dispatch(InfoUpdateSuccess(dataWithImage));
                            dispatch(InfoUpdateSuccessEnd());
                        })
                        .catch(error => {
                            dispatch(InfoUpdateFail(error));
                        });
                })
            });
    } else {
        axios.patch('/info.json?auth=' + token, data)
            .then(response => {
                dispatch(InfoUpdateSuccess(data));
                dispatch(InfoUpdateSuccessEnd());
            })
            .catch(error => {
                dispatch(InfoUpdateFail(error));
            });
    }
    };
};



//// fetch data

export const InfoFetchStart = _ => {
    return {
        type: actionTypes.INFO_FETCH_START
    };
};

export const InfoFetchSuccess = (data) => {
    return {
        type: actionTypes.INFO_FETCH_SUCCESS,
        data: data
    };
};

export const InfoFetchFail = error => {
    return {
        type: actionTypes.INFO_FETCH_FAIL,
        error: error
    };
};

export const fetchInfo = () => {
    return dispatch => {
        dispatch(InfoFetchStart());

        axios.get('/info.json')
            .then(response => {
                dispatch(InfoFetchSuccess(response.data));
            })
            .catch(error => {
                dispatch(InfoFetchFail(error));
            });
    }
};
