import React from 'react';
import logoImg from '../../assets/images/logo.png';

import classes from './Logo.module.css'

const logo = (props) => (
    <div className={classes.Logo}>
        <img src={logoImg} width='80' alt='Falafeeling' /> 
    </div>
);

export default logo;