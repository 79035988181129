import React from 'react';

import classes from './InstagramButton.module.css';

const instagramButton = props => {
    return ( 
        <a href={props.link} target='_blank' rel="noopener noreferrer"  className={classes.Link}>
            <div className={classes.InstagramButton}>
                <i className='fa fa-instagram'> </i> INSTAGRAM
            </div>  
        </a>
     );
}
 
export default instagramButton;