import React, { Component } from 'react';
import { connect } from 'react-redux';
import counterpart from 'counterpart';

import axios from '../../../axios-firebase';

import Spinner from '../../../components/UI/Spinner/Spinner';
import TextHeader from '../../../components/UI/TextHeader/TextHeader';
import TransCap from '../../../components/TransCap/TransCap';

import withErrorHandler from '../../../hoc/withErrorHandler';
import * as actions from '../../../store/actions/index';

import Input from '../../../components/UI/Input/Input';

import { checkValid } from '../../../shared/utilities';


import classes from './ContactUs.module.css';

class ContactUs extends Component {
    state = { 
         controls: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: counterpart('main.contactUs.namePh'),
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false,
                valueType: counterpart('main.contactUs.name'),
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: counterpart('main.contactUs.emailPh'),
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false,
                valueType: counterpart('main.contactUs.email'),
            },
            subject: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: counterpart('main.contactUs.subjectPh'),
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false,
                valueType: counterpart('main.contactUs.subject'),
            },
            message: {
                elementType: 'textarea',
                elementConfig: {
                    type: 'text',
                    placeholder: counterpart('main.contactUs.messagePh'),
                    rows: '4'
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                valueType: counterpart('main.contactUs.message'),
            },
        },
        formIsValid: false,
     }

     componentDidMount() {
         this.props.onInit();
         this.props.onFetchContactInfo();
         this.props.onFetchSocial();
     }
    
     inputChangedHandler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: checkValid(event.target.value, this.state.controls[controlName].validation),
                touched: true
            }
        };

        let formIsValid = true;
        for (let inputIdent in updatedControls) {
            formIsValid = updatedControls[inputIdent].valid && formIsValid;
        }

        this.setState({ controls: updatedControls, formIsValid: formIsValid });
    };

     submitHandler = event => {
        event.preventDefault();

        const formData = {};

        for (let formElementIdent in this.state.controls) {
            formData[formElementIdent] = this.state.controls[formElementIdent].value;
        }

        const today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const dateTime = date + ' ' + time;

        const data = {
            ...formData,
            datetime: dateTime,
            read: false
        }

        this.props.onSendMessage(data);
    };

    render() { 
        const formElementsArray = [];

        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key],
            });
        }
        let btnSend = (
            <button
                className="btn btn-success"
                disabled={!this.state.formIsValid}>
                <i className="fa fa-paper-plane" aria-hidden="true"></i> {counterpart('main.contactUs.send')}
            </button>
        );

        let form = (
                <div>
                {
                    formElementsArray.map(el => (
                    <div className="form-group" key={el.id}>
                        <label
                            style={{ textTransform: 'capitalize' }}>
                            {el.config.valueType}
                        </label>
                        <Input
                            elementType={el.config.elementType}
                            elementConfig={el.config.elementConfig}
                            value={el.config.value}
                            invalid={!el.config.valid}
                            shouldValidate={el.config.validation}
                            touched={el.config.touched}
                            valueType={el.config.valueType}
                            changed={(event) => this.inputChangedHandler(event, el.id)} />
                    </div>
                    ))
                }
                {btnSend}
                </div>
            );


        if(this.props.sending){
            form = <Spinner size='50px' />;
        }

        if(this.props.sent){
            form = <h1 className='alert alert-success text-center'>  <TransCap text='main.contactUs.messageSent' /> </h1>;
        }

        return ( 
            <div className={classes.ContactUs}>
                <h1> <TransCap text='main.contactUs.sendUsAMsg' /> </h1>

                <div className='row'>
                    <div className='col-lg-8'>
                        <form onSubmit={this.submitHandler}>
                        {form}

                       
                        </form>
                    </div>
                    <div className={['col-lg-4', classes.Right].join(' ')} >
                        <TextHeader text=<TransCap text='email' /> />
                        <h4 className='mb-4 text-center'> <a href={'mailto:' + this.props.contactInfo.email}> {this.props.contactInfo.email} </a> </h4>

                        <TextHeader text=<TransCap text='phone' /> />
                        <h4 className='mb-4 text-center'> <a href={'tel:' + this.props.contactInfo.phone}> {this.props.contactInfo.phone} </a> </h4>

                        
                        {/* <TextHeader text='Facebook' />
                        <div className='text-center'> 
                            <iframe 
                                title='fb'
                                src={this.props.social.facebookIFrame} 
                                width="100%" 
                                height="200" 
                                style={{border:'none', overflow:'hidden'}}
                                allow="encrypted-media">
                            </iframe>

                          
                        </div> */}
                    </div>
                </div>
            </div>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        contactInfo: state.contact.data,
        loadingContactInfo: state.contact.loading,
        sending: state.messages.loading,
        sent: state.messages.sent,
        loadingSocial: state.social.loading,
        social: state.social.data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchContactInfo: () => dispatch(actions.fetchContactInfo()),
        onSendMessage: (data) => dispatch(actions.sendmessage(data)),
        onInit: () => dispatch(actions.messageSendInit()),
        onFetchSocial: () => dispatch(actions.fetchSocial())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(ContactUs, axios));