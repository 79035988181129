import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import './fonts/OpenSans-Regular.ttf';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import authReducer from './store/reducers/auth';
import messagesReducer from './store/reducers/messages';
import infoReducer from './store/reducers/info';
import menuReducer from './store/reducers/menus';
import itemReducer from './store/reducers/items';
import addressReducer from './store/reducers/address';
import contactReducer from './store/reducers/contact';
import socialReducer from './store/reducers/social';
import oTimeReducer from './store/reducers/openingTimes';
import galleryReducer from './store/reducers/gallery';
import visitsReducer from './store/reducers/visits';
import dashboardReducer from './store/reducers/dashboard';


// const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    messages: messagesReducer,
    info: infoReducer,
    menus: menuReducer,
    items: itemReducer,
    address: addressReducer,
    contact: contactReducer,
    social: socialReducer,
    oTimes: oTimeReducer,
    gallery: galleryReducer,
    visits: visitsReducer,
    dashboard: dashboardReducer
});

// const store = createStore(rootReducer, composeEnhancers(
//     applyMiddleware(thunk)
// ));

const store = createStore(rootReducer,
    applyMiddleware(thunk)
);



const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
