
// Auth
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

// Messages
export const MESSAGE_SEND_INIT = 'MESSAGE_SEND_INIT';
export const MESSAGE_SEND_START = 'MESSAGE_SEND_START';
export const MESSAGE_SEND_SUCCESS = 'MESSAGE_SEND_SUCCESS';
export const MESSAGE_SEND_FAIL = 'MESSAGE_SEND_FAIL';

export const MESSAGES_FETCH_START = 'MESSAGES_FETCH_START';
export const MESSAGES_FETCH_SUCCESS = 'MESSAGES_FETCH_SUCCESS';
export const MESSAGES_FETCH_FAIL = 'MESSAGES_FETCH_FAIL';

export const MESSAGE_FINDBYID_START = 'MESSAGE_FINDBYID_START';
export const MESSAGE_FINDBYID_SUCCESS = 'MESSAGE_FINDBYID_SUCCESS';
export const MESSAGE_FINDBYID_FAIL = 'MESSAGE_FINDBYID_FAIL';

export const MESSAGE_DELETE_START = 'MESSAGE_DELETE_START';
export const MESSAGE_DELETE_SUCCESS = 'MESSAGE_DELETE_SUCCESS';
export const MESSAGE_DELETE_FAIL = 'MESSAGE_DELETE_FAIL';

export const MESSAGE_MARK_SUCCESS = 'MESSAGE_MARK_SUCCESS';


// Info

export const INFO_UPDATE_START = 'INFO_UPDATE_START';
export const INFO_UPDATE_SUCCESS = 'INFO_UPDATE_SUCCESS';
export const INFO_UPDATE_SUCCESS_END = 'INFO_UPDATE_SUCCESS_END';
export const INFO_UPDATE_FAIL = 'INFO_UPDATE_FAIL';

export const INFO_FETCH_START = 'INFO_FETCH_START';
export const INFO_FETCH_SUCCESS = 'INFO_FETCH_SUCCESS';
export const INFO_FETCH_FAIL = 'INFO_FETCH_FAIL';



// Menus
export const MENU_ADD_INIT = 'MENU_ADD_INIT';
export const MENU_ADD_START = 'MENU_ADD_START';
export const MENU_ADD_SUCCESS = 'MENU_ADD_SUCCESS';
export const MENU_ADD_FAIL = 'MENU_ADD_FAIL';

export const MENU_UPDATE_SUCCESS = 'MENU_UPDATE_SUCCESS';

export const MENUS_FETCH_START = 'MENUS_FETCH_START';
export const MENUS_FETCH_SUCCESS = 'MENUS_FETCH_SUCCESS';
export const MENUS_FETCH_FAIL = 'MENUS_FETCH_FAIL';

export const MENU_DELETE_START = 'MENU_DELETE_START';
export const MENU_DELETE_SUCCESS = 'MENU_DELETE_SUCCESS';
export const MENU_DELETE_FAIL = 'MENU_DELETE_FAIL';

// Items
export const ITEM_ADD_INIT = 'ITEM_ADD_INIT';
export const ITEM_ADD_START = 'ITEM_ADD_START';
export const ITEM_ADD_SUCCESS = 'ITEM_ADD_SUCCESS';
export const ITEM_ADD_FAIL = 'ITEM_ADD_FAIL';

export const ITEM_UPDATE_SUCCESS = 'ITEM_UPDATE_SUCCESS';

export const ITEMS_FETCH_START = 'ITEMS_FETCH_START';
export const ITEMS_FETCH_SUCCESS = 'ITEMS_FETCH_SUCCESS';
export const ITEMS_FETCH_FAIL = 'ITEMS_FETCH_FAIL';

export const ITEM_DELETE_START = 'ITEM_DELETE_START';
export const ITEM_DELETE_SUCCESS = 'ITEM_DELETE_SUCCESS';
export const ITEM_DELETE_FAIL = 'ITEM_DELETE_FAIL';

// Address

export const ADDRESS_UPDATE_START = 'ADDRESS_UPDATE_START';
export const ADDRESS_UPDATE_SUCCESS = 'ADDRESS_UPDATE_SUCCESS';
export const ADDRESS_UPDATE_SUCCESS_END = 'ADDRESS_UPDATE_SUCCESS_END';
export const ADDRESS_UPDATE_FAIL = 'ADDRESS_UPDATE_FAIL';

export const ADDRESS_FETCH_START = 'ADDRESS_FETCH_START';
export const ADDRESS_FETCH_SUCCESS = 'ADDRESS_FETCH_SUCCESS';
export const ADDRESS_FETCH_FAIL = 'ADDRESS_FETCH_FAIL';

// Contact

export const CONTACT_UPDATE_START = 'CONTACT_UPDATE_START';
export const CONTACT_UPDATE_SUCCESS = 'CONTACT_UPDATE_SUCCESS';
export const CONTACT_UPDATE_SUCCESS_END = 'CONTACT_UPDATE_SUCCESS_END';
export const CONTACT_UPDATE_FAIL = 'CONTACT_UPDATE_FAIL';

export const CONTACT_FETCH_START = 'CONTACT_FETCH_START';
export const CONTACT_FETCH_SUCCESS = 'CONTACT_FETCH_SUCCESS';
export const CONTACT_FETCH_FAIL = 'CONTACT_FETCH_FAIL';

// Social Media

export const SOCIAL_UPDATE_START = 'SOCIAL_UPDATE_START';
export const SOCIAL_UPDATE_SUCCESS = 'SOCIAL_UPDATE_SUCCESS';
export const SOCIAL_UPDATE_SUCCESS_END = 'SOCIAL_UPDATE_SUCCESS_END';
export const SOCIAL_UPDATE_FAIL = 'SOCIAL_UPDATE_FAIL';

export const SOCIAL_FETCH_START = 'SOCIAL_FETCH_START';
export const SOCIAL_FETCH_SUCCESS = 'SOCIAL_FETCH_SUCCESS';
export const SOCIAL_FETCH_FAIL = 'SOCIAL_FETCH_FAIL';


// Menus
export const OTIME_ADD_INIT = 'OTIME_ADD_INIT';
export const OTIME_ADD_START = 'OTIME_ADD_START';
export const OTIME_ADD_SUCCESS = 'OTIME_ADD_SUCCESS';
export const OTIME_ADD_FAIL = 'OTIME_ADD_FAIL';

export const OTIME_UPDATE_SUCCESS = 'OTIME_UPDATE_SUCCESS';

export const OTIMES_FETCH_START = 'OTIMES_FETCH_START';
export const OTIMES_FETCH_SUCCESS = 'OTIMES_FETCH_SUCCESS';
export const OTIMES_FETCH_FAIL = 'OTIMES_FETCH_FAIL';

export const OTIME_DELETE_START = 'OTIME_DELETE_START';
export const OTIME_DELETE_SUCCESS = 'OTIME_DELETE_SUCCESS';
export const OTIME_DELETE_FAIL = 'OTIME_DELETE_FAIL';


//Gallery
export const GALLERY_FETCH_START = 'GALLERY_FETCH_START';
export const GALLERY_FETCH_SUCCESS = 'GALLERY_FETCH_SUCCESS';
export const GALLERY_FETCH_FAIL = 'GALLERY_FETCH_FAIL';

export const GALLERY_ADD_INIT = 'GALLERY_ADD_INIT';
export const GALLERY_ADD_START = 'GALLERY_ADD_START';
export const GALLERY_ADD_SUCCESS = 'GALLERY_ADD_SUCCESS';
export const GALLERY_ADD_FAIL = 'GALLERY_ADD_FAIL';

export const GALLERY_DELETE_START = 'GALLERY_DELETE_START';
export const GALLERY_DELETE_SUCCESS = 'GALLERY_DELETE_SUCCESS';
export const GALLERY_DELETE_FAIL = 'GALLERY_DELETE_FAIL';

export const GALLERY_UPLOADFILE_START = 'GALLERY_UPLOADFILE_START';
export const GALLERY_UPLOADFILE_PROGRESS = 'GALLERY_UPLOADFILE_PROGRESS';
export const GALLERY_UPLOADFILE_SUCCESS = 'GALLERY_UPLOADFILE_SUCCESS';
export const GALLERY_UPLOADFILE_FAIL = 'GALLERY_UPLOADFILE_FAIL';


// Visits
export const VISITORS_FETCH_START = 'VISITORS_FETCH_START';
export const VISITORS_FETCH_SUCCESS = 'VISITORS_FETCH_SUCCESS';
export const VISITORS_FETCH_FAIL = 'VISITORS_FETCH_FAIL';

// Dashboard
export const DASHBOARD_MENUS_FETCH_START = 'DASHBOARD_MENUS_FETCH_START';
export const DASHBOARD_MENUS_FETCH_SUCCESS = 'DASHBOARD_MENUS_FETCH_SUCCESS';
export const DASHBOARD_MENUS_FETCH_FAIL = 'DASHBOARD_MENUS_FETCH_FAIL';

export const DASHBOARD_ITEMS_FETCH_START = 'DASHBOARD_ITEMS_FETCH_START';
export const DASHBOARD_ITEMS_FETCH_SUCCESS = 'DASHBOARD_ITEMS_FETCH_SUCCESS';
export const DASHBOARD_ITEMS_FETCH_FAIL = 'DASHBOARD_ITEMS_FETCH_FAIL';

export const DASHBOARD_GALLERY_FETCH_START = 'DASHBOARD_GALLERY_FETCH_START';
export const DASHBOARD_GALLERY_FETCH_SUCCESS = 'DASHBOARD_GALLERY_FETCH_SUCCESS';
export const DASHBOARD_GALLERY_FETCH_FAIL = 'DASHBOARD_GALLERY_FETCH_FAIL';

export const DASHBOARD_MESSAGES_FETCH_START = 'DASHBOARD_MESSAGES_FETCH_START';
export const DASHBOARD_MESSAGES_FETCH_SUCCESS = 'DASHBOARD_MESSAGES_FETCH_SUCCESS';
export const DASHBOARD_MESSAGES_FETCH_FAIL = 'DASHBOARD_MESSAGES_FETCH_FAIL';