import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';

// Fetch

export const dashboardMenusFetchStart = () => {
    return {
        type: actionTypes.DASHBOARD_MENUS_FETCH_START
    };
};

export const dashboardMenusFetchSuccess = (menusCount) => {
    return {
        type: actionTypes.DASHBOARD_MENUS_FETCH_SUCCESS,
        menusCount: menusCount
    };
};

export const dashboardMenusFetchFail = error => {
    return {
        type: actionTypes.DASHBOARD_MENUS_FETCH_FAIL,
        error: error
    };
};

const fetchDashboardMenusHelper = (dispatch) => {
     axios.get('/menus.json')
            .then(response => {
                const fetchedData = [];
                for (let key in response.data) {
                    fetchedData.push({
                        ...response.data[key],
                        id: key,
                    });
                }
                const menusCount = fetchedData.length;

                dispatch(dashboardMenusFetchSuccess(menusCount));
            })
            .catch(error => {
                dispatch(dashboardMenusFetchFail(error));
            });
};


export const fetchDashboardMenus = () => {
    return dispatch => {
        dispatch(dashboardMenusFetchStart());

        fetchDashboardMenusHelper(dispatch );
       
    }
};



export const dashboardItemsFetchStart = () => {
    return {
        type: actionTypes.DASHBOARD_ITEMS_FETCH_START
    };
};

export const dashboardItemsFetchSuccess = (itemsCount) => {
    return {
        type: actionTypes.DASHBOARD_ITEMS_FETCH_SUCCESS,
        itemsCount: itemsCount
    };
};

export const dashboardItemsFetchFail = error => {
    return {
        type: actionTypes.DASHBOARD_ITEMS_FETCH_FAIL,
        error: error
    };
};

const fetchDashboardItemsHelper = (dispatch) => {
        axios.get('/menus.json')
            .then(response => {
                let itemsCount = 0;

                for (let key in response.data) {
                    const items = [];
                    for(let innerKey in response.data[key]){
                        if(innerKey === 'items'){
                            for(let innerKey2 in response.data[key][innerKey]){
                                items.push({
                                    ...response.data[key][innerKey][innerKey2],
                                    id: innerKey2
                                });
                            }
                            
                        }
                    }
                    itemsCount += items.length;
                }

                dispatch(dashboardItemsFetchSuccess(itemsCount));
            })
            .catch(error => {
                dispatch(dashboardItemsFetchFail(error));
            });
};


export const fetchDashboardItems = () => {
    return dispatch => {
        dispatch(dashboardItemsFetchStart());

        fetchDashboardItemsHelper(dispatch);
       
    }
};



export const dashboardGalleryFetchStart = () => {
    return {
        type: actionTypes.DASHBOARD_GALLERY_FETCH_START
    };
};

export const dashboardGalleryFetchSuccess = (galleryCount) => {
    return {
        type: actionTypes.DASHBOARD_GALLERY_FETCH_SUCCESS,
        galleryCount: galleryCount
    };
};

export const dashboardGalleryFetchFail = error => {
    return {
        type: actionTypes.DASHBOARD_GALLERY_FETCH_FAIL,
        error: error
    };
};

const fetchDashboardGalleryHelper = (dispatch ) => {
    axios.get('/gallery.json')
            .then(response => {
                const fetchedData = [];
                for (let key in response.data) {
                    fetchedData.push({
                        ...response.data[key],
                        id: key,
                    });
                }
                const galleryCount = fetchedData.length;

                dispatch(dashboardGalleryFetchSuccess(galleryCount));
            })
            .catch(error => {
                dispatch(dashboardGalleryFetchFail(error));
            });
};


export const fetchDashboardGallery = () => {
    return dispatch => {
        dispatch(dashboardGalleryFetchStart());

        fetchDashboardGalleryHelper(dispatch );
       
    }
};



export const dashboardMessagesFetchStart = () => {
    return {
        type: actionTypes.DASHBOARD_MESSAGES_FETCH_START
    };
};

export const dashboardMessagesFetchSuccess = (messagesCount) => {
    return {
        type: actionTypes.DASHBOARD_MESSAGES_FETCH_SUCCESS,
        messagesCount: messagesCount
    };
};

export const dashboardMessagesFetchFail = error => {
    return {
        type: actionTypes.DASHBOARD_MESSAGES_FETCH_FAIL,
        error: error
    };
};

const fetchDashboardMessagesHelper = (dispatch, token ) => {
    axios.get('/messages.json?auth=' + token)
            .then(response => {
                const fetchedData = [];
                for (let key in response.data) {
                    fetchedData.push({
                        ...response.data[key],
                        id: key,
                    });
                }
                const messagesCount = fetchedData.length;

                dispatch(dashboardMessagesFetchSuccess(messagesCount));
            })
            .catch(error => {
                dispatch(dashboardMessagesFetchFail(error));
            });
};


export const fetchDashboardMessages = (token) => {
    return dispatch => {
        dispatch(dashboardMessagesFetchStart());

        fetchDashboardMessagesHelper(dispatch, token );
       
    }
};