import React from 'react';

import TextHeader from '../TextHeader/TextHeader';

import classes from './MenuLoader.module.css';

const menuLoader = () => {

    const loader = (
        <div className={[classes.Loader, 'mb-4'].join(' ')}>
            <TextHeader text='&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' />
            <div className={classes.Item}>
                <div className={classes.Img}> </div>
                <div className={classes.ItemBody}>
                  &nbsp;&nbsp;
                </div>
            </div>
            <div className={classes.Item}>
                <div className={classes.Img}> </div>
                <div className={classes.ItemBody}>
                  &nbsp;&nbsp;
                </div>
            </div>
            <div className={classes.Item}>
                <div className={classes.Img}> </div>
                <div className={classes.ItemBody}>
                  &nbsp;&nbsp;
                </div>
            </div>
             <div className={classes.Item}>
                <div className={classes.Img}> </div>
                <div className={classes.ItemBody}>
                  &nbsp;&nbsp;
                </div>
            </div>
            <div className={classes.Item}>
                <div className={classes.Img}> </div>
                <div className={classes.ItemBody}>
                  &nbsp;&nbsp;
                </div>
            </div>
            <div className={classes.Item}>
                <div className={classes.Img}> </div>
                <div className={classes.ItemBody}>
                  &nbsp;&nbsp;
                </div>
            </div>
             <div className={classes.Item}>
                <div className={classes.Img}> </div>
                <div className={classes.ItemBody}>
                  &nbsp;&nbsp;
                </div>
            </div>
            <div className={classes.Item}>
                <div className={classes.Img}> </div>
                <div className={classes.ItemBody}>
                  &nbsp;&nbsp;
                </div>
            </div>
            <div className={classes.Item}>
                <div className={classes.Img}> </div>
                <div className={classes.ItemBody}>
                  &nbsp;&nbsp;
                </div>
            </div>
            <div className='clearfix'> </div>
        </div>
    );


    return ( 
        <div>
            {loader}
        </div>
     );
}
 
export default menuLoader;