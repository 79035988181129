import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../../shared/utilities';

const initialState = {
    menusCount: 0,
    itemsCount: 0,
    messagesCount: 0,
    galleryCount: 0,

    loadingMenus: false,
    errorMenus: null,
    loadingItems: false,
    errorItems: null,
    loadingGallery: false,
    errorGallery: null,
    loadingMessages: false,
    errorMessages: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        
        case actionTypes.DASHBOARD_MENUS_FETCH_START:
            return updateObject(state, { loadingMenus: true });

        case actionTypes.DASHBOARD_MENUS_FETCH_SUCCESS:
            return updateObject(state, {
                loadingMenus: false,
                menusCount: action.menusCount
            });

        case actionTypes.DASHBOARD_MENUS_FETCH_FAIL:
            return updateObject(state, { loadingMenus: false,  errorMenu: action.error });

        ///

        case actionTypes.DASHBOARD_ITEMS_FETCH_START:
            return updateObject(state, { loadingItems: true });

        case actionTypes.DASHBOARD_ITEMS_FETCH_SUCCESS:
            return updateObject(state, {
                loadingItems: false,
                itemsCount: action.itemsCount
            });

        case actionTypes.DASHBOARD_ITEMS_FETCH_FAIL:
            return updateObject(state, { loadingItems: false,  errorItems: action.error });

        ///

        case actionTypes.DASHBOARD_GALLERY_FETCH_START:
            return updateObject(state, { loadingGallery: true });

        case actionTypes.DASHBOARD_GALLERY_FETCH_SUCCESS:
            return updateObject(state, {
                loadingGallery: false,
                galleryCount: action.galleryCount
               
            });

        case actionTypes.DASHBOARD_GALLERY_FETCH_FAIL:
            return updateObject(state, { loadingGallery: false,  errorGallery: action.error });

        ///

        case actionTypes.DASHBOARD_MESSAGES_FETCH_START:
            return updateObject(state, { loadingMessages: true });

        case actionTypes.DASHBOARD_MESSAGES_FETCH_SUCCESS:
            return updateObject(state, {
                loadingMessages: false,
                messagesCount: action.messagesCount
            });

        case actionTypes.DASHBOARD_MESSAGES_FETCH_FAIL:
            return updateObject(state, { loadingMessages: false,  errorMessages: action.error });


        default:
            return state;
    }
};


export default reducer;
