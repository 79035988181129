import React from 'react';

import classes from './MenuButtonsLoader.module.css';

const menuButtonsLoader = () => {
    return ( 
        <div className={classes.MenuBtns}>
            <div className={classes.BtnMenu}>
                &nbsp;
            </div>  
            <div className={classes.BtnMenu}>
                 &nbsp;
            </div>
            <div className={classes.BtnMenu}>
                 &nbsp;
            </div>  
            <div className={classes.BtnMenu}>
                 &nbsp;
            </div>
        </div>
     );
}
 
export default menuButtonsLoader;