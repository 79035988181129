import React from 'react';

import classes from './FacebookButton.module.css';

const facebookButton = props => {
    return ( 
        <a 
            href={props.link} 
            target='_blank' 
            rel="noopener noreferrer" 
            className={classes.Link}>
            <div className={classes.FacebookButton}>
                <i className='fa fa-facebook-square'> </i> FACEBOOK
            </div>
        </a>  
     );
}
 
export default facebookButton;