import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImgsViewer from 'react-images-viewer';

import axios from '../../../axios-firebase';

import SideHeader from '../../../components/UI/SideHeader/SideHeader';
import TextHeader from '../../../components/UI/TextHeader/TextHeader';
import GalleryLoader from '../../../components/UI/GalleryLoader/GalleryLoader';
import TransCap from '../../../components/TransCap/TransCap';

import withErrorHandler from '../../../hoc/withErrorHandler';
import * as actions from '../../../store/actions/index';

import classes from './Gallery.module.css';

class Gallery extends Component {
    
    state = {
        isViewerOpen: false,
        currentImage: 0
    }

    componentDidMount() {
        this.props.onFetchGallery();
    }

    viewImageHandler = image => {
        this.setState({
            ...this.state,
            currentImage: image,
            isViewerOpen: true
        });
    };

    closeViewerHandler = () => {
        this.setState({
            ...this.state,
            currentImage: 0,
            isViewerOpen: false
        });
    }

    clickNextHandler = () => {
        this.setState(currentState => ({
            ...this.state,
            currentImage: currentState.currentImage + 1
        }))
    }

    
    clickPrevHandler = () => {
        this.setState(currentState => ({
            ...this.state,
            currentImage: currentState.currentImage - 1
        }))
    }


    render() { 

        let gallery = <GalleryLoader />;
        let imageViewer = null;

        if(!this.props.loading && this.props.images.length > 0){

            const IMG_SET  = [];

            gallery = this.props.images.map((g, index) => (
                <div 
                    key={g.id} 
                    className={classes.Image}
                    onClick={() => this.viewImageHandler(index)}>
                    <img src={g.imageUrl} alt={g.caption} />
                </div>
            ));

            for (let i = 0; i < this.props.images.length; i++) {
                IMG_SET.push({
                    src: this.props.images[i].imageUrl,
                    srcSet: this.props.images[i].imageUrl,
                    caption: this.props.images[i].caption
                });
            }

            imageViewer = (
                <ImgsViewer
                    imgs={IMG_SET}
                    currImg={+this.state.currentImage}
                    isOpen={this.state.isViewerOpen}
                    onClose={this.closeViewerHandler}
                    onClickNext={this.clickNextHandler}
                    onClickPrev={this.clickPrevHandler}
                    showImgCount={true}
                />
            );
        }

        return ( 
            <div className={classes.Gallery}>
                <SideHeader text=<TransCap text='mainNav.gallery' textStyle='uppercase' /> />

                <div className={classes.Images}>
                    <TextHeader text=<TransCap text='main.gallery.ourGallery' /> />
                    {gallery}
                </div>

                {imageViewer}                 
            </div>
         );
    }
}
 
 
const mapStateToProps = state => {
    return {
        images: state.gallery.data,
        loading: state.gallery.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchGallery: () => dispatch(actions.fetchGallery())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Gallery, axios));