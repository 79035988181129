import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../../shared/utilities';

const initialState = {
    visitors: [],
    visitsCount: 0,
    loading: false,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        
        case actionTypes.VISITORS_FETCH_START:
            return updateObject(state, { loading: true });

        case actionTypes.VISITORS_FETCH_SUCCESS:
            const visitsCount = action.data.length;
            return updateObject(state, {
                loading: false,
                visitors: action.data,
                visitsCount: visitsCount
            });

        case actionTypes.VISITORS_FETCH_FAIL:
            return updateObject(state, { loading: false,  error: action.error });


        default:
            return state;
    }
};


export default reducer;
