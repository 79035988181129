import React from 'react';

import classes from './HomeLoader.module.css';

const homeLoader = () => {
    return ( 
         <div className='row'>
            <div className='col-lg-7' >
                <div className={classes.Text}> </div>
            </div>
            <div className='col-lg-5'>
                <div className={classes.Img}> </div>
            </div>
        </div>
     );
}
 
export default homeLoader;