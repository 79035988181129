import React, { Fragment } from 'react';

import Logo from '../../Logo/Logo';
import NavItems from '../../Navigation/NavItems/NavItems';
import Backdrop from '../../UI/Backdrop/Backdrop';

import classes from './SideDrawer.module.css'

const sideDrawer = (props) => {

    let attachedClasses = [classes.SideDrawer, classes.Close];

    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open];
    }

    return (
        <Fragment>
            <Backdrop
                show={props.open}
                clicked={props.closed} />

            <div className={attachedClasses.join(' ')} onClick={props.closed}>
                <Logo />
                <hr />
                <nav>
                    <NavItems  />
                </nav>
            </div>
        </Fragment>

    );
};

export default sideDrawer;