import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';


export const menuAddInit = () => {
    return {
        type: actionTypes.MENU_ADD_INIT
    };
}

export const menuAddStart = _ => {
    return {
        type: actionTypes.MENU_ADD_START
    };
};

export const menuAddSuccess = (id, data) => {
    return {
        type: actionTypes.MENU_ADD_SUCCESS,
        id: id,
        data: data
    };
};

export const menuUpdateSuccess = ( data) => {
    return {
        type: actionTypes.MENU_ADD_SUCCESS,
        data: data
    };
};

export const menuAddFail = error => {
    return {
        type: actionTypes.MENU_ADD_FAIL,
        error: error
    };
};

export const addMenu = (data, token) => {
    return dispatch => {
        dispatch(menuAddStart());

        axios.post('/menus.json?auth=' + token, data)
            .then(response => {
                dispatch(menuAddSuccess(response.data.name ,data));
            })
            .catch(error => {
                dispatch(menuAddFail(error));
            });
    }
};


export const updateMenu = (data, token, menuId) => {
    return dispatch => {
        dispatch(menuAddStart());

        axios.patch('/menus/' + menuId + '.json?auth=' + token, data)
            .then(response => {
                dispatch(menuUpdateSuccess(data));
            })
            .catch(error => {
                dispatch(menuAddFail(error));
            });
    };
};


// Fetch

export const menusFetchStart = _ => {
    return {
        type: actionTypes.MENUS_FETCH_START
    };
};

export const menusFetchSuccess = (data) => {
    return {
        type: actionTypes.MENUS_FETCH_SUCCESS,
        data: data
    };
};

export const menusFetchFail = error => {
    return {
        type: actionTypes.MENUS_FETCH_FAIL,
        error: error
    };
};

const fetchMenusHelper = dispatch => {
     axios.get('/menus.json')
            .then(response => {
                const fetchedData = [];
                for (let key in response.data) {
                    fetchedData.push({
                        ...response.data[key],
                        id: key,
                    });
                }
                fetchedData.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
                dispatch(menusFetchSuccess(fetchedData));
            })
            .catch(error => {
                dispatch(menusFetchFail(error));
            });
};


export const fetchMenus = () => {
    return dispatch => {
        dispatch(menusFetchStart());

        fetchMenusHelper(dispatch);
       
    }
};

// Delete

export const menuDeleteStart = _ => {
    return {
        type: actionTypes.MENU_DELETE_START
    };
};

export const menuDeleteSuccess = () => {
    return {
        type: actionTypes.MENU_DELETE_SUCCESS,
    };
};

export const menuDeleteFail = error => {
    return {
        type: actionTypes.MENU_DELETE_FAIL,
        error: error
    };
};

export const deleteMenu = (id, token) => {
    return dispatch => {
        dispatch(menuDeleteStart());

        axios.delete('/menus/' + id + '.json?auth=' + token)
            .then(response => {
                dispatch(menuDeleteSuccess());
                fetchMenusHelper(dispatch);
            })
            .catch(error => {
                dispatch(menuDeleteFail(error));
            });
    }
};
