import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import counterpart from 'counterpart';

import Input from '../../components/UI/Input/Input'
import Container from '../../components/UI/Container/Container';
import Spinner from '../../components/UI/Spinner/Spinner';
import TransCap from '../../components/TransCap/TransCap';

import { checkValid } from '../../shared/utilities';

import * as actions from '../../store/actions/index';

import classes from './Auth.module.css'

class Auth extends Component {

    state = {
        controls: {
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: counterpart('auth.email'),
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false,
                valueType: 'Email',
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: counterpart('auth.password'),
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false,
                valueType: 'Password',
            },
        },
    };

    inputChangedHandler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: checkValid(event.target.value, this.state.controls[controlName].validation),
                touched: true
            }
        };
        this.setState({ controls: updatedControls });
    };

    submitHandler = (event) => {
        event.preventDefault();

        this.props.onAuth(
            this.state.controls.email.value,
            this.state.controls.password.value,
            this.state.isSignup
        );
    };

    render() {
        const formElementsArray = [];

        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key],
            });
        }

        let form = formElementsArray.map(el => (
            <Input
                key={el.id}
                elementType={el.config.elementType}
                elementConfig={el.config.elementConfig}
                value={el.config.value}
                invalid={!el.config.valid}
                shouldValidate={el.config.validation}
                touched={el.config.touched}
                valueType={el.config.valueType}
                changed={(event) => this.inputChangedHandler(event, el.id)}
            />

        ));

        let authRedirect = null;
        if (this.props.isAuthenticated) {
            authRedirect = <Redirect to="/admin/dashboard" />
        }

        let btn = <button className="btn btn-dark btn-block"> Login</button>;

        if (this.props.loading) {
            btn = <Spinner size="15px" />
        }
        return (
            <Container>
                <div className={classes.Auth}>
                    <h3><TransCap text='auth.login' /></h3>
                    <hr />
                    <form onSubmit={this.submitHandler}>
                        {form}
                        {btn}
                    </form>

                    {this.props.error}
                </div>

                {authRedirect}
                {this.props.isAuthenticated ? <Redirect to="/admin/dashboard" /> : null}
            </Container>
        );
    }
}


const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password, isSignup) => dispatch(actions.auth(email, password, isSignup)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);