import React, { Component } from 'react';

import Auth from '../Auth/Auth';

import classes from './Layout.module.css';

class Layout extends Component {
    render() { 
        return ( 
            <div className={classes.Layout}>
                <Auth />
            </div>
         );
    }
}
 
export default Layout;