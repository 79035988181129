import React from 'react';

import classes from './TextHeader.module.css';

const textHeader = props => {
    return (
        <React.Fragment>
            <h2 className={[classes.CatTitle, classes.CatTitleColor].join(' ')}>
                {props.text}
            </h2>
            <p className={[classes.CatTitleAfter, classes.CatTitleAfterColor].join(' ')}></p>
        </React.Fragment>
    );
}

export default textHeader;