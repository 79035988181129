import React, { useState } from 'react';
import ImgsViewer from 'react-images-viewer';

import classes from './Item.module.css';

const Item = props => {

    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');

    const viewImageHandler = image => {
        setCurrentImage(image);
        setIsViewerOpen(true);
    };

    const closeViewerHandler = () => {
        setCurrentImage('');
        setIsViewerOpen(false);
    }

    return ( 
        <div className={classes.Item}>
            <div className={classes.ItemImage}>
                <img src={props.img} alt='' onClick={() => viewImageHandler(props.img)}  />
            </div>
            <div className={classes.ItemBody}>
                <h4>
                    <strong> {props.title} </strong>
                    - {props.price}€
                </h4>
                <p >
                    {props.description}
                </p>
            </div>

            <ImgsViewer
                imgs={[{ src: currentImage }]}
                currImg={0}
                isOpen={isViewerOpen}
                onClose={closeViewerHandler}
            />
        </div>
     );
}
 
export default Item;