import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';
import { storage } from '../../firebase';

export const itemAddInit = () => {
    return {
        type: actionTypes.ITEM_ADD_INIT
    };
}

export const itemAddStart = _ => {
    return {
        type: actionTypes.ITEM_ADD_START
    };
};

export const itemAddSuccess = (id, data) => {
    return {
        type: actionTypes.ITEM_ADD_SUCCESS,
        id: id,
        data: data
    };
};

export const itemUpdateSuccess = ( data) => {
    return {
        type: actionTypes.ITEM_UPDATE_SUCCESS,
        data: data
    };
};

export const itemAddFail = error => {
    return {
        type: actionTypes.ITEM_ADD_FAIL,
        error: error
    };
};

export const addItem = (data, token, imageData) => {
    return dispatch => {
        dispatch(itemAddStart());
        const menu = data.menu;
         if (imageData) {

            const image = imageData;
            const fname = new Date().getTime() + '_' + image.name;

            const uploadTask = storage.ref(`images/${fname}`).put(image);

            uploadTask.on('state_changed',
                (snapshot) => {
                    // progrss function ....
                },
                (error) => {
                    // error function ....
                    //console.log(error);
                    dispatch(itemAddFail(error));
                },
                () => {
                    // complete function ....
                    storage.ref('images').child(fname).getDownloadURL().then(url => {

                        const dataWithImage = {
                            ...data,
                            imageUrl: url,
                            image: {
                                imageUrl: url,
                                imageSize: image.size,
                                imageName: fname
                            }
                        }

                        axios.post('/menus/' + menu + '/items.json?auth=' + token, dataWithImage)
                            .then(response => {
                                dispatch(itemAddSuccess(dataWithImage));
                            })
                            .catch(error => {
                                dispatch(itemAddFail(error));
                            });

                        axios.post('/gallery.json?auth=' + token, {imageUrl: url, caption: data.title})
                            .then(response => {

                            });
                    })
                });
        } else {
            axios.post('/menus/' + menu + '/items.json?auth='  + token, data)
                .then(response => {
                    dispatch(itemAddSuccess(data));
                })
                .catch(error => {
                    dispatch(itemAddFail(error));
                });
        }
    };
};


export const updateItem = (data, token, itemId, imageData) => {
    return dispatch => {
        dispatch(itemAddStart());
        const menu = data.menu;
         if (imageData) {

            const image = imageData;
            const fname = new Date().getTime() + '_' + image.name;
            const uploadTask = storage.ref(`images/${fname}`).put(image);

            uploadTask.on('state_changed',
                (snapshot) => {
                    // progrss function ....
                },
                (error) => {
                    // error function ....
                    //console.log(error);
                    dispatch(itemAddFail(error));
                },
                () => {
                    // complete function ....
                    storage.ref('images').child(fname).getDownloadURL().then(url => {

                        const dataWithImage = {
                            ...data,
                            imageUrl: url,
                            image: {
                                imageUrl: url,
                                imageSize: image.size,
                                imageName: fname
                            }
                        }

                        axios.patch('/menus/' + menu + '/items/' + itemId +'.json?auth=' + token, dataWithImage)
                            .then(response => {
                                dispatch(itemUpdateSuccess(dataWithImage));
                            })
                            .catch(error => {
                                dispatch(itemAddFail(error));
                            });

                        axios.post('/gallery.json?auth=' + token, {imageUrl: url, caption: data.title})
                            .then(response => {

                            });
                    })
                });
        } else {
            axios.patch('/menus/' + menu + '/items/' + itemId +'.json?auth='  + token, data)
                .then(response => {
                    dispatch(itemUpdateSuccess(data));
                })
                .catch(error => {
                    dispatch(itemAddFail(error));
                });
        }
    };
};


// Fetch

export const itemsFetchStart = _ => {
    return {
        type: actionTypes.ITEMS_FETCH_START
    };
};

export const itemsFetchSuccess = (data) => {
    return {
        type: actionTypes.ITEMS_FETCH_SUCCESS,
        data: data
    };
};

export const itemsFetchFail = error => {
    return {
        type: actionTypes.ITEMS_FETCH_FAIL,
        error: error
    };
};

const adminFetchItemsHelper = dispatch => {
     axios.get('/menus.json?orderBy="order"')
            .then(response => {
                const fetchedData = [];

                for (let key in response.data) {
                    const items = [];
                    for(let innerKey in response.data[key]){
                        if(innerKey === 'items'){
                            for(let innerKey2 in response.data[key][innerKey]){
                                items.push({
                                    ...response.data[key][innerKey][innerKey2],
                                    id: innerKey2
                                });
                            }
                            
                        }
                    }

                     fetchedData.push({
                        ...response.data[key],
                        id: key,
                        items: items
                    });
                }
               // console.log(fetchedData)
               fetchedData.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
                dispatch(itemsFetchSuccess(fetchedData));
            })
            .catch(error => {
                dispatch(itemsFetchFail(error));
            });
};


export const adminFetchItems = () => {
    return dispatch => {
        dispatch(itemsFetchStart());

        adminFetchItemsHelper(dispatch);
       
    }
};

const fetchItemsHelper = (dispatch, menuId) => {
     axios.get('/menus/' + menuId +'.json')
            .then(response => {
                const fetchedData = [];
                // console.log(response.data)

                const items = [];
                    
                for(let innerKey in response.data.items){
                    items.push({
                        ...response.data.items[innerKey],
                        id: innerKey
                    });
                }
                
                fetchedData.push({
                    id: menuId,
                    menu_FR: response.data.menu_FR,
                    menu_EN: response.data.menu_EN,
                    items: items
                });
                
            //    console.log(fetchedData)
                dispatch(itemsFetchSuccess(fetchedData));
            })
            .catch(error => {
                console.log(error)
                dispatch(itemsFetchFail(error));
            });
};


export const fetchItems = (id) => {
    return dispatch => {
        dispatch(itemsFetchStart());

        fetchItemsHelper(dispatch,id);
       
    }
};


// Delete

export const itemDeleteStart = _ => {
    return {
        type: actionTypes.ITEM_DELETE_START
    };
};

export const itemDeleteSuccess = () => {
    return {
        type: actionTypes.ITEM_DELETE_SUCCESS,
    };
};

export const itemDeleteFail = error => {
    return {
        type: actionTypes.ITEM_DELETE_FAIL,
        error: error
    };
};

export const deleteItem = (menuId, id, token) => {
    return dispatch => {
        dispatch(itemDeleteStart());

        axios.delete('/menus/'+ menuId + '/items/' + id + '.json?auth=' + token)
            .then(response => {
                dispatch(itemDeleteSuccess());
                adminFetchItemsHelper(dispatch);
            })
            .catch(error => {
                dispatch(itemDeleteFail(error));
            });
    }
};
