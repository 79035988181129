import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';


//// Update data

export const contactUpdateStart = () => {
    return {
        type: actionTypes.CONTACT_UPDATE_START
    };
};

export const contactUpdateSuccess = (data) => {
    return {
        type: actionTypes.CONTACT_UPDATE_SUCCESS,
        data: data
    };
};


export const contactUpdateSuccessEnd = () => {
    return {
        type: actionTypes.CONTACT_UPDATE_SUCCESS_END,
    };
};

export const contactUpdateFail = error => {
    return {
        type: actionTypes.CONTACT_UPDATE_FAIL,
        error: error
    };
};

export const updateContact = (data, token) => {
    return dispatch => {
        dispatch(contactUpdateStart());

        axios.patch('/contact.json?auth=' + token, data)
            .then(response => {
                dispatch(contactUpdateSuccess(data));
                dispatch(contactUpdateSuccessEnd());
            })
            .catch(error => {
                dispatch(contactUpdateFail(error));
            });
    }
};

// Fetch

export const contactFetchStart = () => {
    return {
        type: actionTypes.CONTACT_FETCH_START
    };
};

export const contactFetchSuccess = (data) => {
    return {
        type: actionTypes.CONTACT_FETCH_SUCCESS,
        data: data
    };
};

export const contactFetchFail = error => {
    return {
        type: actionTypes.CONTACT_FETCH_FAIL,
        error: error
    };
};

export const fetchContactInfo = () => {
    return dispatch => {
        dispatch(contactFetchStart());

        axios.get('/contact.json')
            .then(response => {
                dispatch(contactFetchSuccess(response.data));
            })
            .catch(error => {
                dispatch(contactFetchFail(error));
            });
    }
};