import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../../shared/utilities';

const initialState = {
    data: {},
    loading: false,
    error: null,
    posted: false,
    deleting: false,
    deleted: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OTIME_ADD_INIT:
            return updateObject(state, { loading: false, posted: false, error: null });

        case actionTypes.OTIME_ADD_START:
            return updateObject(state, { loading: true });

        case actionTypes.OTIME_ADD_SUCCESS:
            const newOTime = updateObject(action.data, { id: action.id });
            return updateObject(state, {
                loading: false,
                posted: true,
                error: null,
                data: state.data.concat(newOTime)
            });

        case actionTypes.OTIME_ADD_FAIL:
            return updateObject(state, { loading: false, posted: false, error: action.error });

        case actionTypes.OTIME_UPDATE_SUCCESS:
            return updateObject(state, {
                loading: false,
                posted: true,
                error: null,
            });


        case actionTypes.OTIMES_FETCH_START:
            return updateObject(state, { loading: true, deleted: false });

        case actionTypes.OTIMES_FETCH_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: action.data
            });

        case actionTypes.OTIMES_FETCH_FAIL:
            return updateObject(state, { loading: false,  error: action.error });


        case actionTypes.OTIME_DELETE_START:
            return updateObject(state, { deleting: true });

        case actionTypes.OTIME_DELETE_SUCCESS:
           
            return updateObject(state, {
                deleting: false,
                deleted: true,
                error: null,
            });

        case actionTypes.OTIME_DELETE_FAIL:
            return updateObject(state, { deleting: false, deleted: false, error: action.error });

        default:
            return state;
    }
};


export default reducer;
