import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';


//// Update data

export const socialUpdateStart = () => {
    return {
        type: actionTypes.SOCIAL_UPDATE_START
    };
};

export const socialUpdateSuccess = (data) => {
    return {
        type: actionTypes.SOCIAL_UPDATE_SUCCESS,
        data: data
    };
};

export const socialUpdateSuccessEnd = () => {
    return {
        type: actionTypes.SOCIAL_UPDATE_SUCCESS_END,
    };
};

export const socialUpdateFail = error => {
    return {
        type: actionTypes.SOCIAL_UPDATE_FAIL,
        error: error
    };
};

export const updateSocial = (data, token) => {
    return dispatch => {
        dispatch(socialUpdateStart());

        axios.patch('/social.json?auth=' + token, data)
            .then(response => {
                dispatch(socialUpdateSuccess(data));
                dispatch(socialUpdateSuccessEnd());
            })
            .catch(error => {
                dispatch(socialUpdateFail(error));
            });
    }
};

// Fetch

export const socialFetchStart = () => {
    return {
        type: actionTypes.SOCIAL_FETCH_START
    };
};

export const socialFetchSuccess = (data) => {
    return {
        type: actionTypes.SOCIAL_FETCH_SUCCESS,
        data: data
    };
};

export const socialFetchFail = error => {
    return {
        type: actionTypes.SOCIAL_FETCH_FAIL,
        error: error
    };
};

export const fetchSocial = () => {
    return dispatch => {
        dispatch(socialFetchStart());

        axios.get('/social.json')
            .then(response => {
                dispatch(socialFetchSuccess(response.data));
            })
            .catch(error => {
                dispatch(socialFetchFail(error));
            });
    }
};