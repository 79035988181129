import React from 'react';

import classes from './NavItems.module.css';
import NavItem from './NavItem/NavItem';

import TransCap from '../../TransCap/TransCap';

const navItems = (props) => (
    <ul className={classes.NavItems}>
        <NavItem link="/" exact> <i className="fa fa-home" aria-hidden="true"></i> <TransCap text='mainNav.home' />
        </NavItem>
        <NavItem link="/menu" exact><i className="fa fa-list-alt" aria-hidden="true"></i> <TransCap text='mainNav.menu' />
        </NavItem>
        <NavItem link="/location" exact><i className="fa fa-map" aria-hidden="true"></i> <TransCap text='mainNav.location' />
        </NavItem>
        <NavItem link="/gallery" exact><i className="fa fa-photo" aria-hidden="true"></i> <TransCap text='mainNav.gallery' />
        </NavItem>
        <NavItem link="/contact-us" exact><i className="fa fa-envelope" aria-hidden="true"></i> <TransCap text='mainNav.contactUs' />
        </NavItem>



    </ul>
);

export default navItems;