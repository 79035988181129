import React, { Component } from 'react';
import { Route, withRouter, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import counterpart from 'counterpart';

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import * as actions from './store/actions/index';

// import asyncComponent from './hoc/asyncComponent';

import en from './translation/en';
import fr from './translation/fr';

import Logout from './containers/Auth/Logout/Logout';
import MainLayout from './containers/Main/Layout';
import AuthLayout from './containers/Auth/Layout';
import AdminLayout from './containers/Admin/Layout';

// const AuthLayout = React.lazy(() => import('./containers/Auth/Layout'));
// const AdminLayout = React.lazy(() => import('./containers/Admin/Layout'));

// const AuthLayout = asyncComponent(() => {
//   return import('./containers/Auth/Layout');
// });

// const AdminLayout = asyncComponent(() => {
//   return import('./containers/Admin/Layout');
// });


const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('fr', fr);

counterpart.setLocale('fr');

class App extends Component {

  state = {
    lang: 'fr'
  }


  componentDidMount() {
    this.props.onTryAutoSignin();
    
    const loader = document.getElementById('appLoader');
     if(loader){
        loader.outerHTML = '';
      }
  }


  render() {

    return (
      <React.Fragment>
        <ReactNotification />
        <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path="/login" component={AuthLayout} />
              <Route path="/logout" component={Logout} />
              <Route path="/admin" component={this.props.isAuthenticated ? AdminLayout : AuthLayout} />
              <Route path="/" component={MainLayout} />
              <Redirect from='/*' to="/" />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </React.Fragment>
    );
  }

}


const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignin: () => dispatch(actions.authCheckState())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

