import React from 'react';
import Translate from 'react-translate-component';

import classes from './TransCap.module.css';

const transCap = props => {

    let cls = classes.TransCap;

    if(props.textStyle === 'uppercase') {
        cls = classes.TransCapUppercase
    }
    return  <Translate content={props.text} className={cls} />
}

export default transCap;