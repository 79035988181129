import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';


//// Update data

export const addressUpdateStart = _ => {
    return {
        type: actionTypes.ADDRESS_UPDATE_START
    };
};

export const addressUpdateSuccess = (data) => {
    return {
        type: actionTypes.ADDRESS_UPDATE_SUCCESS,
        data: data
    };
};

export const addressUpdateSuccessEnd = () => {
    return {
        type: actionTypes.ADDRESS_UPDATE_SUCCESS_END,
    };
};

export const addressUpdateFail = error => {
    return {
        type: actionTypes.ADDRESS_UPDATE_FAIL,
        error: error
    };
};

export const updateAddress = (data, token) => {
    return dispatch => {
        dispatch(addressUpdateStart());

        axios.patch('/address.json?auth=' + token, data)
            .then(response => {
                dispatch(addressUpdateSuccess(data));
                dispatch(addressUpdateSuccessEnd());
            })
            .catch(error => {
                dispatch(addressUpdateFail(error));
            });
    }
};

// Fetch


export const addressFetchStart = _ => {
    return {
        type: actionTypes.ADDRESS_FETCH_START
    };
};

export const addressFetchSuccess = (data) => {
    return {
        type: actionTypes.ADDRESS_FETCH_SUCCESS,
        data: data
    };
};

export const addressFetchFail = error => {
    return {
        type: actionTypes.ADDRESS_FETCH_FAIL,
        error: error
    };
};

export const fetchAddress = () => {
    return dispatch => {
        dispatch(addressFetchStart());

        axios.get('/address.json')
            .then(response => {
                dispatch(addressFetchSuccess(response.data));
            })
            .catch(error => {
                dispatch(addressFetchFail(error));
            });
    }
};


