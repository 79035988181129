import React from 'react';

import classes from './Container.module.css';

const container = props => {

    const style = [classes.Container, 'container', 'text-center'];

    return ( 
        <div className={style.join(' ')}>
            {props.children}
        </div>
     );
}
 
export default container;